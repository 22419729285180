import React from 'react';
import './projects.css';

const Projects = () => {
  return (
    <div>
      <header></header>
      <main>
        <div class='projects-box'>
          <h1>UNDER CONSTRUCTION...</h1>
        </div>
      </main>
    </div>
  );
};

export default Projects;
